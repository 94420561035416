import { trigger, transition, animate, style } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// component

import { SidenavCardComponent } from 'layout/sidenav-card/sidenav-card.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  currentURL: string;

  mobileQuery: MediaQueryList;

  showSideBar: boolean;

  resumeURL: string;

  // Listener for viewport changes
  private _mobileQueryListener: () => void;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.showSideBar = false;
    this.mobileQuery = media.matchMedia('(max-width: 1023px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.resumeURL =
      'https://bhavish14.s3.us-east-2.amazonaws.com/Bhavish+Narayanan.pdf';

    // addEventListener is not supported in safari.
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {}

  redirectToHome(): void {
    this.router.navigate(['/']);
    this.currentURL = '/';
  }

  redirectTo(path: string): void {
    this.router.navigate([path]);
    this.currentURL = path;
  }

  openSideNavDialog(): void {
    const dialogRef = this.dialog.open(SidenavCardComponent, {
      hasBackdrop: true,
      width: '98%',
      height: '100%',
      position: {
        top: '0px',
        right: '0px',
      },
      panelClass: 'sidenav-dialog',
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.redirectTo(result);
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
