import { trigger, transition, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':leave', [animate('0ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ExperienceComponent implements OnInit {

  showInfoToggle: boolean[];

  constructor() {
    this.showInfoToggle = [true, false, false, false];
  }

  ngOnInit(): void {
  }

  showInformationFor(idx: number): void {
    this.showInfoToggle = [false, false, false, false];
    this.showInfoToggle[idx] = true;
  }

}
