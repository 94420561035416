import { trigger, transition, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sidenav-card',
  templateUrl: './sidenav-card.component.html',
  styleUrls: ['./sidenav-card.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SidenavCardComponent implements OnInit {
  constructor(public thisDialogRef: MatDialogRef<SidenavCardComponent>) {}

  ngOnInit(): void {}

  onCloseCancel(value: string) {
    this.thisDialogRef.close(value);
  }
}
