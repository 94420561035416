import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dogs-of-bhark',
  templateUrl: './dogs-of-bhark.component.html',
  styleUrls: ['./dogs-of-bhark.component.css'],
})
export class DogsOfBharkComponent implements OnInit {
  videoBaseUrl: string;
  showToolbar: boolean = false;
  dogImages: any[];
  dogVideos: any[];

  constructor() {
    this.dogImages = [
      {
        src: 'dogs-of-bhark/Jack.jpeg',
        name: 'Jack',
        title: 'Meet Jack! The in house paw-doctor!',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/Jack-the-weimaraner.jpeg',
        name: 'Jack',
        title: 'The face he makes when I tell him he can get no more pets',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/its-Jack-again.jpeg',
        name: 'Jack',
        title: 'A good boy who demands constant pets',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/Snowy.jpeg',
        name: 'Snowy',
        title: "He's what you'd call George Clooney of dogs",
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/IMG_1115.jpeg',
        name: 'Lily',
        title: 'That underbite tho!',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/Frodo.jpeg',
        name: 'Frodo',
        title: 'Another smol pupper!',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/Sam-and-Jack.jpeg',
        name: 'Jack & Sam',
        title: 'The partners in crime',
        srcType: 'image',
      },
      {
        src: 'dogs-of-bhark/tara.jpeg',
        name: 'Tara',
        title: "She's so smol",
        srcType: 'image',
      },
    ];

    this.dogVideos = [
      {
        src: 'dogs-of-bhark/Sam-being-a-derp.mov',
        name: 'Sam',
        title: 'He loves being a derp',
        srcType: 'video',
      },
      {
        src: 'dogs-of-bhark/Frodo-vid-1.mov',
        name: 'Frodo',
        title: 'When snow is bae!',
        srcType: 'video',
      },
      {
        src: 'dogs-of-bhark/Jack-vid-2.mov',
        name: 'Jack',
        title: 'He loves his toys!',
        srcType: 'video',
      },
      // {
      //   src: 'dogs-of-bhark/Jack.mov',
      //   name: 'Jack',
      //   title: 'He doesn\'t like to share his toys',
      //   srcType: 'video',
      // },
      {
        src: 'dogs-of-bhark/Maple-vid-1.mov',
        name: 'Maple',
        title: 'She loves her bones',
        srcType: 'video',
      },
    ];

    this.videoBaseUrl = 'https://bhavish14.s3.us-east-2.amazonaws.com/';
    this.showToolbar = false;
  }

  ngOnInit() {}
}
