import { Component, OnInit } from '@angular/core';
import { Book } from '@interfaces/book.interface';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css'],
})
export class AboutMeComponent implements OnInit {
  public bookList: Book[];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get('https://bhavish14.s3.us-east-2.amazonaws.com/books/bookList.json')
      .subscribe(
        (res: Book[]) => {
          this.bookList = res;
        },
        (_err) => {
          console.log('failed to get books list from aws.');
          console.log(_err);

          this.bookList = [];
        }
      );
  }
}
