import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';

// components
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HomePageComponent implements OnInit {
  scroll$: Subscription;
  startPosition: number;

  constructor(
    private router: Router // private downloadService: DownloadService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  openProjects(): void {
    this.router.navigate(['/projects']);
  }
}
