import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

// layouts
import { SidenavCardComponent } from '../layout/sidenav-card/sidenav-card.component';
import { HeaderComponent } from 'layout/header/header.component';

// pages
import { AppComponent } from './app.component';
import { HomePageComponent } from 'pages/home-page/home-page.component';
import { DogsOfBharkComponent } from 'pages/dogs-of-bhark/dogs-of-bhark.component';

// components
import { AboutMeComponent } from '@pages/about-me/about-me.component';
import { EducationComponent } from '@pages/education/education.component';
import { ExperienceComponent } from '@pages/experience/experience.component';
import { ProjectsComponent } from '@pages/projects/projects.component';
import { SkillsComponent } from '@pages/skills/skills.component';
import { PageHeaderComponent } from '@pages/page-header/page-header.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'about-me', component: AboutMeComponent },
  { path: 'education', component: EducationComponent },
  { path: 'experience', component: ExperienceComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'skills', component: SkillsComponent },
  { path: 'dogs', component: DogsOfBharkComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // angular material
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
  ],
  declarations: [
    AppComponent,
    HomePageComponent,

    // layout
    HeaderComponent,
    PageHeaderComponent,
    SidenavCardComponent,

    // pages
    AboutMeComponent,
    EducationComponent,
    ExperienceComponent,
    ProjectsComponent,
    SkillsComponent,
    DogsOfBharkComponent,
  ],
  exports: [RouterModule],
})
export class AppModule {}
